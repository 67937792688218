import { EventMessage, EventType, PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.min.css'; //core css
import 'primereact/resources/themes/saga-blue/theme.css'; //theme
import React from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import App from './App';
import { msalConfig } from './auth/authConfig';
import { AppContextProvider } from './contexts/AppContext';
import { AppInsightsEvents } from './enums/AppInsightsEvents';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import { appInsightsPromise, reactPlugin } from './services/ApplicationInsightsService';
import ConfigService from './services/ConfigService';
import { STORAGE_KEYS } from './constants/storageKeys';
import { setLocalStorageItem } from './utils/utils';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const config = ConfigService.getConfig();

config
  .then((data) => {
    msalConfig.auth.clientId = data.auth.clientId;
    msalConfig.auth.authority = data.auth.authority;
    msalConfig.auth.knownAuthorities = data.auth.knownAuthorities;
    const msalInstance = new PublicClientApplication(msalConfig);

    const queryClient = new QueryClient();

    msalInstance.addEventCallback((message: EventMessage) => {
      if (message.eventType === EventType.LOGIN_SUCCESS) {
        const accounts = msalInstance.getAllAccounts();

        // Invalidate user assignments so they get re-loaded after login
        queryClient.invalidateQueries({ queryKey: ['assignments'] });

        // Track user login event in app insights
        appInsightsPromise
          .then((appInsights) => {
            const additionalProperties = {
              Name: accounts[0].name,
              'User ID': accounts[0].username,
            };
            setLocalStorageItem(STORAGE_KEYS.UserLoginId, accounts[0].localAccountId);
            appInsights.trackEvent(
              { name: AppInsightsEvents.UserLoginEvent },
              additionalProperties
            );
          })
          .catch((error) => console.log('Error loading app insights config'));
      }
    });

    root.render(
      <React.StrictMode>
        <MsalProvider instance={msalInstance}>
          <AppInsightsContext.Provider value={reactPlugin}>
            <QueryClientProvider client={queryClient}>
              <AppContextProvider>
                <App />
              </AppContextProvider>
              <ReactQueryDevtools />
            </QueryClientProvider>
          </AppInsightsContext.Provider>
        </MsalProvider>
      </React.StrictMode>
    );

    // If you want to start measuring performance in your app, pass a function
    // to log results (for example: reportWebVitals(console.log))
    // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
    reportWebVitals();
  })
  .catch((error) => console.log('Error reading app config file'));
