import './ScenarioOverviewGraph.scss'
import Graph from "./Graph";
import { useEffect, useState } from 'react';
import DataNotFound from '../data-not-found/DataNotFound';
import { VisualHeading } from '../../enums/VisualAndTableHeading';
import { visualAxisLabels } from '../../enums/VisualAxisLabels';

export interface IScenarioVisualsData {
  heading: string;
  scenarioTotalPrice?: string | number;
  [key: string]: number | string | undefined;
}

interface GraphFields {
  label: string;
  data: string[];
  yAxisID: string;
  type: string
}

export interface IScenarioOverviewGraph {
  scenarioVisualsData: IScenarioVisualsData[][],
  productFamilyNames: string[],
  labels: string[]
}

export interface GraphData {
  labels: string[],
  datasets: (GraphFields | object)[]
}

const ScenarioOverviewGraph = ({ scenarioVisualsData, labels, productFamilyNames }: IScenarioOverviewGraph) => {
  const [data, setData] = useState<(GraphData | object)[]>([]);

  const calculateGraphData = (labels: string[]) => {

    return scenarioVisualsData?.map((item: IScenarioVisualsData[]) => {
      if (!item?.length) return {};

      const datasets = productFamilyNames
        ?.map((drugName) => {
          const drugValues = item.map((scenario) => scenario[drugName]);

          return {
            label: drugName,
            data: drugValues,
            yAxisID: 'left',
            type: 'bar',
          };
        })
        .filter((dataset) => Object.keys(dataset).length);

      return { labels, datasets };
    });
  }

  useEffect(() => {
    const graphData = calculateGraphData(labels);
    setData(graphData);
  }, [scenarioVisualsData]);

  return (
    <div className="graph-box grid">
      {
        VisualHeading.map((heading, index) =>
          heading !== 'Rebate Estimate' ? (
            <div key={`${heading}-${index}`} className="col-6">
              <div className="graph-container">
                <div className="graph-heading-box">
                  <div data-testid="graph-heading" className="graph-heading-text">
                    {heading}
                  </div>
                </div>
                {data[index] && Object.keys(data[index]).length ? (
                  <Graph graphData={data[index]} axisLabel={visualAxisLabels[index]} graphHeadingindex={index} />
                ) : (
                  <DataNotFound
                    text="Data Not Found"
                    className="data-not-found flex align-items-center justify-content-center opacity-70"
                  />
                )}
              </div>
            </div>
          ) : <div key={`${heading}-${index}`} className="col-6"></div>
        )
      }
    </div>
  )
}

export default ScenarioOverviewGraph