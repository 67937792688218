export const visualAxisLabels = [
    {
      yAxisLeft: "EU Volume (Drug Level)"
    },
    {
      yAxisLeft: "Reimbursement (Drug Level)"
    },
    {
      yAxisLeft: "NCR Per EU (Drug Level)"
    },
    {
      yAxisLeft: "Annualized Reimbursements (Drug Level)"
    },
    {
      yAxisLeft: "Annualized NCR (Drug Level)"
    },
    {
      yAxisLeft: "Rebate Estimate (Drug Level)"
    },
  ];