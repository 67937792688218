export const ButtonLabels = {  
  DEFINE_SCENARIOS : 'Define Scenarios',
  GENERATE_SCENARIO : 'Generate Scenario',
  MODIFY_DRUGS : 'Modify Drugs',
  RESET: 'Reset',
  EXPORT: 'Export',
  YES_IAM_SURE: 'Yes, I’m sure',
  NO_CONTINUE: 'No, Continue',
  UNLOCKED: 'Unlocked',
  LOCKED: 'Locked'
};
