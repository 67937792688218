import Chart from 'chart.js/auto'
import { useEffect, useRef } from 'react';
import { formatNumber, formatNumberWithDollarSymbol } from '../../utils/utils';

interface AxisLabels {
  yAxisLeft?: string,
}
interface IGraph {
  graphData: any,
  axisLabel: AxisLabels,
  graphHeadingindex: number
}

const Graph = ({ graphData, axisLabel, graphHeadingindex }: IGraph) => {
  const chartRef = useRef<any>(null);
  const chartInstance = useRef<any>(null);

  const barDataPoint = {
    id: 'barDataPoint',
    afterDatasetsDraw(chart: any) {
      const { ctx } = chart;
      const metaData = chart?._sortedMetasets;

      if (graphHeadingindex === 2 || graphHeadingindex === 4) {
        metaData?.forEach((data: any) => {
          data?.data?.forEach((barDetail: any) => {
          ctx.save();
          ctx.font = '11px sans-serif';
          ctx.fillStyle = ((graphHeadingindex === 2 || graphHeadingindex === 4) && barDetail.$context?.raw) ? 'black' : 'transparent';
          ctx.textAlign = 'center';
          ctx.fillText(formatNumberWithDollarSymbol(barDetail.$context?.raw), barDetail?.x, barDetail?.y - 7);
          ctx.restore();
          })
        })
      }
    }
  }

  const scenarioTotalLabel = {
    id: 'scenarioTotalLabel',
    afterDatasetsDraw(chart: any) {
      const { ctx, data, scales: { left } } = chart;
      const yHidden: any = [];

      if (graphHeadingindex !== 2 && graphHeadingindex !== 4) {
        for (let i = 0; i < data?.labels?.length; i++) {
          const dataPointRow = data?.datasets?.map((dataset: any, index: number) => {
            let yCoordinate;

            if (chart?.getDatasetMeta(index).hidden === true) {
              yCoordinate = 1000;
            } else {
              yCoordinate = chart.getDatasetMeta(index).data[i].y;
            }
            return yCoordinate;
          });
          yHidden.push(dataPointRow);
        }

        data?.datasets[0]?.data?.forEach((dataPoint: any, index: any) => {
          const yPosition = Math.min(...yHidden[index]);
          ctx.save();
          ctx.font = '11px sans-serif';
          ctx.fillStyle = left?.getValueForPixel(yPosition) ? 'black' : 'transparent';
          ctx.textAlign = 'center';
          ctx.fillText(axisLabel?.yAxisLeft?.includes('Volume') ? formatNumber(left?.getValueForPixel(yPosition)) : formatNumberWithDollarSymbol(left?.getValueForPixel(yPosition)), chart.getDatasetMeta(0).data[index].x, yPosition - 6);
          ctx.restore();
        });
      }
    }
  }

  useEffect(() => {
    if (chartInstance.current) {
      chartInstance.current.destroy();
    }
    const myChartRef = chartRef?.current?.getContext('2d');
    chartInstance.current = new Chart(myChartRef, {
      type: "bar",
      data: graphData,
      options: {
        devicePixelRatio: 2,
        responsive: true,
        maintainAspectRatio: false,
        interaction: {
          mode: 'index',
          intersect: false,
        },
        plugins: {
          legend: {
            position: 'bottom',
            labels: {
              boxWidth: 15,
              boxHeight: 15,
              font: {
                size: 8,
                weight: 700
              }
            }
          },
          tooltip: {
            callbacks: {
              label: (context) => {
                let label = context.dataset.label ?? '';

                if (label) {
                  label += ': ';
                }

                const { y } = context.parsed;
                const isVolume = axisLabel?.yAxisLeft?.includes('Volume');

                if (y !== null && y !== 0) {
                  return isVolume ? label + formatNumber(y) : label + formatNumberWithDollarSymbol(y);
                }

                return isVolume ? label + 0 : label + '$0';
              }
            }
          }
        },
        scales: {
          x: {
            display: true,
            stacked: (graphHeadingindex !== 2 && graphHeadingindex !== 4),
            ticks: {
              font: {
                size: 10,
                weight: 700
              }
            },
            grid: {
              display: false
            }
          },
          left: {
            display: true,
            stacked: (graphHeadingindex !== 2 && graphHeadingindex !== 4),
            position: 'left',
            beginAtZero: true,
            grace: 500,
            ticks: {
              count: 6,
              font: {
                size: 10,
                weight: 700
              },
              callback: (value: any) => {
                return axisLabel?.yAxisLeft?.includes('Volume') ? formatNumber(value) : formatNumberWithDollarSymbol(value);
              }
            },
            grid: {
              display: false
            }
          }
        },
      },
      plugins: [scenarioTotalLabel, barDataPoint]
    });
  }, []);


  return (
    <div data-testid='graph' className='flex align-items-center justify-content-center' style={{ position: 'relative', height: 'calc(100vh - 330px)' }}>
      <canvas ref={chartRef} />
    </div>
  );
}

export default Graph;