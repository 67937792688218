import { createContext, Dispatch, ReactNode, useContext, useEffect, useMemo, useReducer } from "react";
import { Category, Scenario } from "../pages/home/Home";
import { getSessionStorageItem } from "../utils/utils";

export enum CategoryActionType {
  DEFINE_SCENARIO = 'DEFINE_SCENARIO',
  SET_INITIAL_STATE = 'SET_INITIAL_STATE'
}

export interface IInitialCategoryState {
  selectedCategory: Category | undefined,
  scenarios: Scenario[],
  categories: Category[],
}

export interface ICategoryAction {
  type: CategoryActionType;
  payload?: any;
}

const initialState: IInitialCategoryState = {
  selectedCategory: undefined,
  scenarios: [],
  categories: [],
}

export interface ICategoryDrugsContextType {
  state: IInitialCategoryState,
  dispatch: Dispatch<ICategoryAction>
}

export const categoryReducer = (
  state: IInitialCategoryState,
  action: ICategoryAction
): IInitialCategoryState => {
  switch (action.type) {
    case CategoryActionType.DEFINE_SCENARIO: {
      const newState = {
        ...state,
        selectedCategory: action?.payload?.selectedCategory,
        scenarios: action?.payload?.scenarios,
        categories: action?.payload?.categories,
      };
      return newState;
    }
    case CategoryActionType.SET_INITIAL_STATE:
      return action.payload
  }
}

interface ICategoryProviderProps {
  children: ReactNode
}

const CategoryContext = createContext<{ state: IInitialCategoryState, dispatch: Dispatch<ICategoryAction> } | undefined>(undefined);

const useCategoryContext = () => {
  const context = useContext(CategoryContext);

  if (!context) {
    throw new Error('CategoryContext must be used within a CategoryContexProvider');
  }
  return context;
};

const CategoryContextProvider = ({ children }: ICategoryProviderProps) => {
  const [state, dispatch] = useReducer(categoryReducer, initialState);

  useEffect(() => {
    const newState = getSessionStorageItem('category');

    if (newState) {
      const updatedState = JSON.parse(newState);
      dispatch({ type: CategoryActionType.SET_INITIAL_STATE, payload: updatedState });
    }
  }, []);

  const contextValue = useMemo<ICategoryDrugsContextType>(
    () => ({
      state,
      dispatch
    }), [state]
  )
  return <CategoryContext.Provider value={contextValue}>{children}</CategoryContext.Provider>;
};

export { CategoryContextProvider, useCategoryContext, CategoryContext };