import './ScenarioOverview.scss';
import ScenarioOverviewGraph from "../../components/graph/ScenarioOverviewGraph";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from 'react-router-dom';
import ScenarioOverviewTable from "../../components/scenario-overview-table/ScenarioOverviewTable";
import { SecondaryButton } from "../../components/global/SecondaryButton";
import { SelectButton, SelectButtonChangeEvent } from 'primereact/selectbutton';
import { deleteSessionStorageItem, formatNumber, formatNumberWithDollarSymbol } from "../../utils/utils";
import { handleExportGraphPDF, handleExportTable } from "../../utils/filesExportUtils";
import Loader from "../../components/loader/Loader";
import { ScenarioActionType, StoredFutureScenario, useScenarioContext } from "../../contexts/ScenarioModelScenarioContext";
import { CategoryActionType, useCategoryContext } from "../../contexts/ScenarioModelCategoryContext";
import { ButtonLabels } from "../../enums/ButtonLabels";
import { FinancialScenarioResponse } from "../home/Home";
import { TableHeading } from '../../enums/VisualAndTableHeading';

interface JustifyOption {
  icon: string;
  value: string;
}

export interface ScenarioOverviewCalculatedData {
  heading: string;
  [key: string]: any;
}

export interface ScenarioOverviewTableData extends ScenarioOverviewCalculatedData {
  REPORT_TYPE: string
}

const ScenarioOverview = () => {
  const [scenarioOverviewCalculatedData, setScenarioOverviewCalculatedData] = useState<ScenarioOverviewCalculatedData[][]>([]);
  const [scenarioOverviewGraphCalculatedData, setScenarioOverviewGraphCalculatedData] = useState<ScenarioOverviewCalculatedData[][]>([]);
  const [scenarioOverviewNonEUTableData, setScenarioOverviewNonEUTableData] = useState<ScenarioOverviewTableData[]>([]);
  const [scenarioOverviewEUTableData, setScenarioOverviewEUTableData] = useState<ScenarioOverviewTableData[]>([]);
  const [isTableView, setIsTableView] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const reportTemplateRef = useRef(null);
  const { state } = useScenarioContext();
  const { dispatch: categoryContextProviderDispatch } = useCategoryContext();
  const { dispatch: scenariosContextProviderDispatch } = useScenarioContext();
  const { scenarios: data } = state;
  const navigate = useNavigate();
  const justifyOptions: JustifyOption[] = [
    { icon: 'pi pi-table', value: 'table' },
    { icon: 'pi pi-chart-line', value: 'graph' },
  ];
  const [value, setValue] = useState<string>(justifyOptions[0].value);
  const visualLabels = data?.map(scenario => scenario?.heading)
  const newArray = data?.map(scenario => scenario?.value?.length);
  const index = newArray?.indexOf(Math.max(...newArray));
  const productFamilyNames = data[index]?.value?.map((productFamily: FinancialScenarioResponse) => productFamily?.productFamilyName);

  const handleClick = (e: string | null) => {
    setValue(e ?? value);
    setIsTableView(e ? !isTableView : isTableView);
  };

  const handleReset = () => {
    categoryContextProviderDispatch({ type: CategoryActionType.DEFINE_SCENARIO });
    scenariosContextProviderDispatch({ type: ScenarioActionType.DEFINE_SCENARIOS });
    navigate('/');
  }

  const handleModifyDrugs = () => {
    scenariosContextProviderDispatch({ type: ScenarioActionType.DEFINE_SCENARIOS });
    deleteSessionStorageItem('scenarios');
    navigate('/');
  }

  const exportPDF = () => {
    setIsLoading(true);
    const promise = isTableView ? handleExportTable(scenarioOverviewEUTableData, scenarioOverviewNonEUTableData, visualLabels) : handleExportGraphPDF(reportTemplateRef);
    promise.then(() => {
      setIsLoading(false);
    });
  };

  const calculateTotal = (data: ScenarioOverviewCalculatedData) => {
    return Object.entries(data)
      .filter(([key]) => key !== "productFamilyName")
      .reduce((sum, [, value]) => sum + (typeof value === "number" ? value : 0), 0);
  }

  useEffect(() => {
    const newEUVolumeData: ScenarioOverviewCalculatedData[] = [];
    const newReimbursementData: ScenarioOverviewCalculatedData[] = [];
    const newNetCostRecoveryData: ScenarioOverviewCalculatedData[] = [];
    const newQuarterlyReimbursementData: ScenarioOverviewCalculatedData[] = [];
    const newQuarterlyNCRData: ScenarioOverviewCalculatedData[] = [];
    const newRebateData: ScenarioOverviewCalculatedData[] = [];

    if (data[0]?.value?.length) {

      data?.forEach((item: StoredFutureScenario) => {
        const transformedEUVolumeData: ScenarioOverviewCalculatedData = {
          heading: item?.heading,
        };
        const transformedReimbursementData: ScenarioOverviewCalculatedData = {
          heading: item?.heading,
        };
        const transformedNetCostRecoveryData: ScenarioOverviewCalculatedData = {
          heading: item?.heading,
        };
        const transformedRebateData: ScenarioOverviewCalculatedData = {
          heading: item?.heading,
        };
        const transformedQuaterlyReimbursementData: ScenarioOverviewCalculatedData = {
          heading: item?.heading,
        };
        const transformedQuaterlyNCRData: ScenarioOverviewCalculatedData = {
          heading: item?.heading,
        };

        item?.value?.forEach(drug => {
          transformedEUVolumeData[drug?.productFamilyName] = drug?.categoryUnits;
          transformedReimbursementData[drug?.productFamilyName] = drug?.estimatedMedicareReimbursementPerEU;
          transformedNetCostRecoveryData[drug?.productFamilyName] = drug?.totalEstimatedNCRPerEU;
          transformedRebateData[drug?.productFamilyName] = drug?.totalRebate;
          transformedQuaterlyNCRData[drug?.productFamilyName] = drug?.totalEstimatedNCR;
          transformedQuaterlyReimbursementData[drug?.productFamilyName] = drug?.estimatedMedicareReimbursement;
        });
        newEUVolumeData.push(transformedEUVolumeData);
        newReimbursementData.push(transformedReimbursementData);
        newNetCostRecoveryData.push(transformedNetCostRecoveryData);
        newRebateData.push(transformedRebateData);
        newQuarterlyNCRData.push(transformedQuaterlyNCRData);
        newQuarterlyReimbursementData.push(transformedQuaterlyReimbursementData);
      });
    }
    setScenarioOverviewGraphCalculatedData([newEUVolumeData, [], newReimbursementData, newQuarterlyReimbursementData, newNetCostRecoveryData, newQuarterlyNCRData]);
    setScenarioOverviewCalculatedData([newEUVolumeData, newReimbursementData, newNetCostRecoveryData, newQuarterlyReimbursementData, newQuarterlyNCRData, newRebateData]);
  }, [state]);

  useEffect(() => {
    const newData = scenarioOverviewCalculatedData.map((scenario, index) => {
      const result: Record<string, any> = {};
      const total: Record<string, any> = { productFamilyName: 'Total' };

      scenario.forEach(item => {
        Object.keys(item).forEach(key => {
          if (key !== "heading") {
            if (!result[key]) {
              result[key] = { productFamilyName: key };
            }
            result[key][item.heading] = TableHeading[index] === TableHeading[0] ? formatNumber(item[key]) : formatNumberWithDollarSymbol(item[key]);

            if (TableHeading[index] !== TableHeading[0] && TableHeading[index] !== TableHeading[1] && TableHeading[index] !== TableHeading[2]) {
              total[item.heading] = formatNumberWithDollarSymbol(calculateTotal(item));
            }
          }
        });
      });
      return [...Object.values(result), total];
    });

    const tableValue: ScenarioOverviewTableData[] = [];
    newData?.forEach((data: any, index: number) => {
      data?.forEach((value: any) => {
        tableValue.push({
          REPORT_TYPE: TableHeading[index],
          ...value,
        })
      })
    });
    const filteredData = tableValue?.filter(value => Object.keys(value)?.length > 2);
    const euTableData = filteredData?.filter(data => data?.REPORT_TYPE === TableHeading[0] || data?.REPORT_TYPE === TableHeading[1] || data?.REPORT_TYPE === TableHeading[2]);
    const nonEUTableData = filteredData?.filter(data => data?.REPORT_TYPE !== TableHeading[0] && data?.REPORT_TYPE !== TableHeading[1] && data?.REPORT_TYPE !== TableHeading[2]);
    setScenarioOverviewNonEUTableData(nonEUTableData);
    setScenarioOverviewEUTableData(euTableData);
  }, [scenarioOverviewCalculatedData]);

  const justifyTemplate = (option: JustifyOption) => {
    return <i data-testid={`${option.value}-view`} className={option.icon} style={{ fontSize: '1.05rem' }}></i>;
  }

  return (
    <div className="main-content">
      <div className="scenario-overview w-full">
        <div className="scenario-overview-card flex align-items-center justify-content-between fixed">
          <div className="text-lg font-bold">{"Scenarios Overview"}</div>
          <div className="scenario-overview-subcard flex align-items-center justify-content-between" >
            <SecondaryButton label={ButtonLabels.RESET} className="text-sm reset-button" onClick={handleReset} />
            <SelectButton className="selectbutton" value={value} onChange={(e: SelectButtonChangeEvent) => handleClick(e.value)} itemTemplate={justifyTemplate} optionLabel="value" options={justifyOptions} />
            <SecondaryButton label={ButtonLabels.MODIFY_DRUGS} icon="pi pi-list" onClick={handleModifyDrugs} className="text-sm" />
            <SecondaryButton label={ButtonLabels.EXPORT} icon="pi pi-upload" className="text-sm" onClick={exportPDF} isDisabled={!scenarioOverviewEUTableData?.length || !scenarioOverviewNonEUTableData.length} />
          </div>
        </div>
        {
          isTableView ?
            <div className='scenario-overview-table-card'>
              <ScenarioOverviewTable data={scenarioOverviewEUTableData} headings={visualLabels} />
              <ScenarioOverviewTable data={scenarioOverviewNonEUTableData} headings={visualLabels} />
            </div> :
            <div ref={reportTemplateRef}>
              <ScenarioOverviewGraph scenarioVisualsData={scenarioOverviewGraphCalculatedData} productFamilyNames={productFamilyNames} labels={visualLabels} />
            </div>
        }
      </div>
      {
        isLoading && <Loader />
      }
    </div>
  )
}

export default ScenarioOverview;