import { FinancialScenarioRequest } from "../pages/home/Home";
import { apiClient } from "./ApiClient"

const getScenarioModelCategories = async (id: string | undefined) => {
  try {
    const result = await apiClient.get(`scenario-model/eligiblecategories?accountNumber=${id}`);
    return result.data;
  } catch (error) {
    console.log('eligibleCategoriesError', error);
    throw error;
  }
};

const getFinancialScenarioDetails = async (request: FinancialScenarioRequest) => {
  try {
    const result = await apiClient.post('scenario-model/financialscenario', request);
    return result.data;
  } catch (error) {
    console.log('futureScenarioDetails', error);
    throw error;
  }
}

const getContactDetails = async (email: string | undefined) => {
  try {
    const result = await apiClient.get(`scenario-model/contactDetails?emailId=${email}`);
    return result.data;
  } catch (error) {
    console.log('userContactDetails', error);
    throw error;
  }
}

const CategoryAndDrugsService = {
  getScenarioModelCategories,
  getFinancialScenarioDetails,
  getContactDetails
};

export default CategoryAndDrugsService;