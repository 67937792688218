import '../../styles/components/Sidebar.scss';
import { Image } from 'primereact/image';
import allyiqLogo from '../../assets/allyiq-logo.svg';
import { useAppContext } from '../../contexts/AppContext';

const Sidebar: React.FC = () => {
  const { isSideBarDisable } = useAppContext();

  return (
    <div className='app-sidebar fixed h-full left-0 m-0 p-0 top-0'>
      {isSideBarDisable && <div className='overlay' data-testid='sidemenu-overlay' />}
      <div className='m-0 p-0'>
        <div className='logo-container'>
          <Image src={allyiqLogo} className='App-logo' alt='AllyIQ' width='100%' height='100%' />
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
