import { ZodError, ZodType } from 'zod';
import { StatusCodes } from '../enums/StatusCodes';
import { Messages } from '../enums/Messages';

/**
 * The function `clearCookies` clears all cookies by setting their expiration date to a past time.
 */
export const clearCookies = () => {
  const cookies = document.cookie.split(';');
  cookies.forEach((cookie) => {
    document.cookie = cookie.split('=')[0] + '=;expires=Thu, 21 Sep 1979 00:00:01 UTC;';
  });
};

/**
 * Calculates the sum of a specific key from an array of items.
 *
 * @param {any[] | undefined} Items - The array of items to calculate the sum from.
 * @param {string} key - The key to use for summing up the values.
 * @return {number} The calculated sum based on the provided key.
 */
export const calculateTheSumBasedOnKey = (Items: any[] | undefined, key: string) => {
  return Items?.reduce((sum, item) => sum + (item[key] ?? 0), 0);
};

/* Get value from local Storage by the key */
export const getLocalStorageItem = (key: string) => localStorage.getItem(key);

/**
 * Sets the local storage with the key and the value
 * @param key
 * @param value
 * @returns
 */
export const setLocalStorageItem = (key: string, value: string) => localStorage.setItem(key, value);

/* Get value from sessionStorage by the key */
export const getSessionStorageItem = (key: string) => sessionStorage.getItem(key);

/**
 * Gets the value from session storage by the key
 * @param key
 * @param value
 * @returns
 */
export const setSessionStorageItem = (key: string, value: string) =>
  sessionStorage.setItem(key, value);

export const deleteSessionStorageItem = (key: string) => sessionStorage.removeItem(key);

type FormValues = { [key: string]: any };

/**
 * Validate the form values based on the given schema, if 
 * any validation mismatch returns zod error against the field.
 * 
 * @param schema zod type schema for form validation
 * @param values 
 * @returns 
 */
export const validateWithSchema = <T extends FormValues>(
  schema: ZodType<T, any>,
  values: T
): any => {
  try {
    schema.parse(values);
  } catch (error) {
    if (error instanceof ZodError) {
      const fieldErrors: { [key: string]: string } = {};
      error.errors.forEach((err) => {
        err.path.forEach((field) => {
          fieldErrors[field] = err.message;
        });
      });
      return fieldErrors;
    }
    throw error;
  }
};

export const formatNumberWithDollarSymbol = (number: number) => {
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 }).format(number);
}

export const formatNumber = (number: number) => {
  return new Intl.NumberFormat('en-IN', { maximumFractionDigits: 0 }).format(number);
}

export const getErrorMessageBasedOnStatusCodes = (code: number) => {
  switch (code) {
    case StatusCodes.BAD_REQUEST_CODE:
      return Messages.BAD_REQUEST_MESSAGE;
    case StatusCodes.UNAUTHORIZED_CODE:
      return Messages.UNAUTHORIZED_MESSAGE;
    case StatusCodes.FORBIDDEN_CODE:
      return Messages.FORBIDDEN_MESSAGE;
    case StatusCodes.RESOURCE_NOT_FOUND_CODE:
      return Messages.RESOURCE_NOT_FOUND_MESSAGE;
    case StatusCodes.SERVER_ERROR_CODE:
      return Messages.SERVER_ERROR_MESSAGE;
    default:
      return Messages.DEFAULT_ERROR_MESSAGE;
  }
}