import { useMutation } from "react-query";
import CategoryAndDrugsService from "../services/CategoryAndDrugsService";
import { FinancialScenarioRequest } from "../pages/home/Home";

interface IUseGetFinancialScenarioDetails {
  onSuccess: (data: any) => void;
  onError: (data: any) => void;
}

export const useGetFinancialScenarioDetails = (props: IUseGetFinancialScenarioDetails) => {
  const onGetFinancialScenarioDetails = useMutation({
    mutationFn: async (request: FinancialScenarioRequest) => {
      return await CategoryAndDrugsService.getFinancialScenarioDetails(request);
    },
    onSuccess: (response: any) => {
      return props?.onSuccess(response);
    },
    onError: (response: any) => {
      const result = response.response;
      return props?.onError(result.status);
    }
  });

  const getFinancialScenarioDetails = (request: FinancialScenarioRequest) => {
    onGetFinancialScenarioDetails.mutate(request);
  };

  return { getFinancialScenarioDetails, onGetFinancialScenarioDetails };
}