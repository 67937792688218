import { useMutation } from "react-query";
import CategoryAndDrugsService from "../services/CategoryAndDrugsService";

interface IUseGetEligibleCategories {
  onSuccess: (data: any) => void;
  onError: (data: any) => void;
}


export const useGetEligibleCategories = (props: IUseGetEligibleCategories) => {
  const onGetEligibleCategories = useMutation({
    mutationFn: async (id: string | undefined) => {
      return await CategoryAndDrugsService.getScenarioModelCategories(id);
    },
    onSuccess: (response: any) => {
      return props?.onSuccess(response);
    },
    onError: (response: any) => {
      const result = response.response;
      return props?.onError(result.status);
    }
  });

  const getEligibleCategories = (id: string | undefined) => {
    onGetEligibleCategories.mutate(id);
  };

  return { getEligibleCategories };
}