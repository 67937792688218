export const Messages = {
  VOLUME_DISTRIBUTION_EXCEEDS: 'Please ensure that the total volume distribution is 100% for ',
  CANCEL_CONFIRMATION: 'Are you sure you want to leave?',
  CANCEL_CONFIRMATION_BODY:
    'If you leave, your changes will not be saved and you will have to start over.',
  LOGOUT_CONFIRM_HEADER: 'Are you sure you want to log out?',
  BAD_REQUEST_MESSAGE : "Some required information is missing. Please check your input and try again.",
  UNAUTHORIZED_MESSAGE : "Your session has expired, or you don’t have access. Please log in and try again.",
  FORBIDDEN_MESSAGE : "You don’t have permission to perform this action. Contact support if needed.",
  RESOURCE_NOT_FOUND_MESSAGE : "No data found. Please verify the details and try again.",
  SERVER_ERROR_MESSAGE : "Something went wrong. Please try again later or contact support.",
  DEFAULT_ERROR_MESSAGE : "Something went wrong. Please try again later or contact support.",
};
