import { useMutation } from "react-query";
import CategoryAndDrugsService from "../services/CategoryAndDrugsService";

interface IUseGetUserContactDetails {
  onSuccess: (data: any) => void;
  onError: (data: any) => void;
}

export const useGetUserContactDetails = (props: IUseGetUserContactDetails) => {
  const onGetUserContactDetails = useMutation({
    mutationFn: async (email: string | undefined) => {
      return await CategoryAndDrugsService.getContactDetails(email);
    },
    onSuccess: (response: any) => {
      return props?.onSuccess(response);
    },
    onError: (response: any) => {
      const result = response.response;
      return props?.onError(result.status);
    }
  });

  const getUserContactDetails = (email: string | undefined) => {
    onGetUserContactDetails.mutate(email);
  };

  return { getUserContactDetails };
}