import React, { createContext, useContext, useMemo, useState } from 'react';
import { GlobalMessageHandle, IGlobalMessage } from '../components/global/GlobalMessage';
import { IGlobalConfirmationModal } from '../components/global/GlobalConfirmationModal';
import { IConfirmationModalProps } from '../components/global/ConfirmationModal';

interface AppContextProps {
  children: React.ReactNode;
}

export interface IAppContextType {
  toastRef: React.RefObject<GlobalMessageHandle> | undefined;
  showGlobalMessage: (props: IGlobalMessage) => void;
  confirmationModalRef: React.RefObject<IGlobalConfirmationModal> | undefined;
  showGlobalConfirmationModal: (props: IConfirmationModalProps) => void;
  isSideBarDisable: boolean;
  setIsSideBarDisable: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AppContext = createContext<IAppContextType | undefined>(undefined);

const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('useAppContext must be used within a AppContextProvider');
  }
  return context;
};

const AppContextProvider = ({ children }: AppContextProps) => {
  const [isSideBarDisable, setIsSideBarDisable] = useState(false);

  const toastRef = React.useRef<GlobalMessageHandle>(null);
  const showGlobalMessage = (props: IGlobalMessage) => {
    toastRef?.current?.showGlobalMessage?.(props);
  };

  const confirmationModalRef = React.useRef<IGlobalConfirmationModal>(null);
  const showGlobalConfirmationModal = (props: IConfirmationModalProps) => {
    confirmationModalRef?.current?.showGlobalConfirmationModal?.(props);
  };

  const contextValue = useMemo<IAppContextType>(
    () => ({
      toastRef,
      showGlobalMessage,
      confirmationModalRef,
      showGlobalConfirmationModal,
      isSideBarDisable: isSideBarDisable,
      setIsSideBarDisable: setIsSideBarDisable,
    }),
    [isSideBarDisable]
  );

  return <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>;
};

export { AppContextProvider, useAppContext };
