import './ScenarioOverviewTable.scss';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { ScenarioOverviewTableData } from '../../pages/scenario-overview/ScenarioOverview';

export interface IScenarioOverviewTable {
  data: ScenarioOverviewTableData[],
  headings: string[]
}

const ScenarioOverviewTable = ({ data, headings }: IScenarioOverviewTable) => {

  const headerGroup = (
    <ColumnGroup>
      <Row>
        <Column colSpan={2} />
        {
          headings.map((heading: string) => (
            <Column className='text-sm' key={heading} header={heading} />
          ))
        }
      </Row>
    </ColumnGroup>
  )

  const drugCellTemplate = (value: ScenarioOverviewTableData, productFamilyName: string) => {
    return (
      <div data-testid='dispensed-drug-price' className={'text-sm table-header'}>
        {value[productFamilyName]}
      </div>
    )
  };

  const rowClass = (data: ScenarioOverviewTableData) => {
    return {
      'bg-gray-100' : data?.productFamilyName === 'Total'
    }
  }

  return (
    <div data-testid='scenario-overview-table' className="scenario-overview-table pb-5 flex justify-content-center w-full">
      <DataTable value={data} className='text-sm w-11' rowClassName={rowClass} headerColumnGroup={headerGroup} rowGroupMode="rowspan" groupRowsBy="REPORT_TYPE" showGridlines>
        <Column field="REPORT_TYPE" className='text-base font-bold current-cell w-3' />
        <Column field='productFamilyName' className='w-2' />
        {
          headings.map((heading: string) => (
            <Column className={`text-sm w-2`} key={heading} field={heading} body={(value) => drugCellTemplate(value, heading)} header={heading} />
          ))
        }
      </DataTable>
    </div>
  )
}

export default ScenarioOverviewTable