import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputNumber } from "primereact/inputnumber";
import { Slider } from "primereact/slider";
import './DefinitionTable.scss';
import 'primeicons/primeicons.css';
import { Dispatch, SetStateAction } from "react";
import { calculateTheSumBasedOnKey } from "../../utils/utils";
import { ProductFamily, Scenario } from "../../pages/home/Home";
import { ButtonLabels } from "../../enums/ButtonLabels";

export interface IDefinitionTable {
  index: number,
  scenarios: Scenario[],
  setScenario: Dispatch<SetStateAction<Scenario[]>>,
}

const DefinitionTable = ({
  index,
  scenarios,
  setScenario
}: IDefinitionTable) => {

  const handleLock = (drug: ProductFamily) => {
    const newDrugs = scenarios[index]?.value?.map((item: ProductFamily) =>
      item.productFamilyName === drug.productFamilyName
        ? { ...item, isLocked: !item.isLocked }
        : item
    );
    const newScenario = { ...scenarios[index], value: newDrugs };
    const newScenarios = scenarios.map((scenario, i) => i === index ? newScenario : scenario);
    setScenario(newScenarios);
  };

  const handleSlideValue = (drug: ProductFamily, value: number) => {
    const newDrugs = scenarios[index]?.value?.map((item: ProductFamily) => {
      return item?.productFamilyName === drug?.productFamilyName ? { ...item, productFamilyToCategoryRatio: value } : item;
    });
    const newScenario = { ...scenarios[index], value: newDrugs };
    const newScenarios = scenarios.map((scenario, i) => i === index ? newScenario : scenario);
    setScenario(newScenarios);
  }

  const volumeDistributionTemplate = (value: any) => {
    return (
      <div className="flex align-items-center gap-3 p-fluid">
        <InputNumber data-testid='input-volume' disabled={value?.isLocked || !index} style={{ width: '16%' }} value={value?.productFamilyToCategoryRatio} onChange={(e: any) => handleSlideValue(value, e.value)} suffix=" %" />
        <Slider data-testid='input-slider' className="w-9" disabled={value?.isLocked || !index} value={value?.productFamilyToCategoryRatio} onChange={(e: any) => handleSlideValue(value, e.value)} />
      </div>
    )
  }

  const lockTemplate = (value: ProductFamily) => {
    return (
      <button type="button" data-testid='lock-cell' className={`text-sm cursor-pointer w-full py-3 border-none bg-white ${value?.isLocked ? 'lock' : 'unlock'}`} disabled={!index} onClick={() => handleLock(value)}>
        {value?.isLocked ? ButtonLabels.LOCKED : ButtonLabels.UNLOCKED}
      </button>
    )
  }

  const drugTemplate = (value: ProductFamily) => {
    return (
      <div className="text-xs font-semibold">
        {value?.productFamilyName}
      </div>
    )
  }

  const volumeDistributionHeaderTemplate = (value: Scenario) => {
    return (
      <div className="flex align-items-center justify-content-between">
        <div>
          {"Volume Distribution"}
        </div>
        {
          <div data-testid='total-volume-distribution' className="bg-white p-1 text-xs">{calculateTheSumBasedOnKey(value?.value, 'productFamilyToCategoryRatio') + ' %'}</div>
        }
      </div>
    )
  }

  return (
    <DataTable data-testid='scenario-definition-data-table' className="scenario-definition-table" value={scenarios[index]?.value} showGridlines>
      <Column className="w-5" header={scenarios[index].heading} body={drugTemplate} />
      <Column className="w-5" header={() => volumeDistributionHeaderTemplate(scenarios[index])} body={volumeDistributionTemplate} />
      <Column header='Lock Volume' body={lockTemplate} />
    </DataTable>
  );
};

export default DefinitionTable;