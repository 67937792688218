import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { MutableRefObject } from "react";
import { ScenarioOverviewTableData } from "../pages/scenario-overview/ScenarioOverview";
import ExcelJS, { Cell, Row, Worksheet } from 'exceljs';
import { saveAs } from 'file-saver';
import { TableHeading } from "../enums/VisualAndTableHeading";

/**
 * Export the graph dashboard into the pdf format.
 * 
 * @param reportTemplateRef reference of the graph dashboard.
 */
export const handleExportGraphPDF = async (reportTemplateRef: MutableRefObject<null>) => {
  if (reportTemplateRef.current) {
    try {
      const canvas = await html2canvas(reportTemplateRef.current, {
        scale: 2,
      });
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF({
        orientation: 'portrait',
        unit: 'px',
        format: [canvas.width - 224, canvas.height],
      });
      pdf.addImage(imgData, 'PNG', 0, 0, canvas.width - 224, canvas.height);
      pdf.save('graph.pdf');
    } catch (error) {
      console.log('chartexport', error);
    }
  }
};

const getTableColumns = (columns: string[]) => {
  const defaultColumns = [{
    key: 'REPORT_TYPE',
    width: 30,
  },
  {
    key: 'productFamilyName',
    width: 20,
  }];
  const productFamilyColumns = columns?.map(column => ({ key: column, header: column, width: 20 }));
  return [...defaultColumns, ...productFamilyColumns];
}

const addTableColumns = (columns: string[], worksheet: Worksheet) => {

  worksheet.columns = getTableColumns(columns);

  worksheet.getRow(1).alignment = { vertical: 'middle' };

}

const addTableRows = (data: ScenarioOverviewTableData[], worksheet: Worksheet) => {
  data?.forEach(value => {
    worksheet.addRow({
      ...value
    });
  });
}

const getRowSpanValue = (data: ScenarioOverviewTableData[], filterKey: string) => {
  return data.filter(tableData => (tableData.REPORT_TYPE === filterKey)).length;
}

const mergeTableRow = (tableHeading: string[], data: ScenarioOverviewTableData[], rowNumber: number, worksheet: Worksheet) => {
  let newRowNumber = rowNumber;
  worksheet.mergeCells(`A${newRowNumber - 1}:B${newRowNumber - 1}`);
  tableHeading.forEach(heading => {
    const rowSpanValue = getRowSpanValue(data, heading);
    worksheet.mergeCells(`A${newRowNumber}:A${rowSpanValue + newRowNumber - 1}`);
    newRowNumber += rowSpanValue;
  });
  return newRowNumber
}

const fillBackgroundColorForCell = (cell: Cell) => {
  cell.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: {
      argb: 'EFECEC'
    },
  };
}

const addCellStyles = (worksheet: Worksheet) => {
  worksheet.eachRow((row: Row) => {
    let isTotalRowCell = false;
    row.eachCell((cell: Cell) => {

      if (!cell?.address?.includes('A') && !cell?.address?.includes('B')) {
        cell.alignment = {
          vertical: 'middle',
          horizontal: 'right'
        }
      }

      if (cell?.value === 'Total') {
        isTotalRowCell = true;
      }

      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
      };

      if (isTotalRowCell) {
        fillBackgroundColorForCell(cell);
      }
    });
  });
}

const fillTableColumn = (row: Row) => {
  row?.eachCell((cell: Cell) => {
    fillBackgroundColorForCell(cell);
  })
}

/**
 * Export the table data into the excel file format.
 * 
 * @param EUData equivalent unit table data
 * @param nonEUData non equivalent unit table data
 * @param columns column values of the table
 */
export const handleExportTable = async (EUData: ScenarioOverviewTableData[], nonEUData: ScenarioOverviewTableData[], columns: string[]) => {
  if (EUData?.length || nonEUData?.length) {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('data');
    addTableColumns(columns, worksheet);
    addTableRows(EUData, worksheet);
    worksheet.addRow({});
    worksheet.addRow({});
    worksheet.addRow({
      'REPORT_TYPE': '',
      'productFamilyName': '',
      'Current': 'Current',
      'Scenario 1': 'Scenario 1'
    });
    addTableRows(nonEUData, worksheet);
    let rowNumber = 2;
    const updatedRowNumber = mergeTableRow(TableHeading.slice(0, 3), EUData, rowNumber, worksheet);
    rowNumber = updatedRowNumber + 3;
    mergeTableRow(TableHeading.slice(3), nonEUData, rowNumber, worksheet);
    addCellStyles(worksheet);
    fillTableColumn(worksheet.getRow(1));
    fillTableColumn(worksheet.getRow(updatedRowNumber + 2));

    try {
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
      saveAs(blob, 'Report');
    } catch (error) {
      console.log(error);
    }
  }
};