import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import React from 'react';
import {
  Navigate,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';
import './App.scss';
import GlobalConfirmationModal from './components/global/GlobalConfirmationModal';
import GlobalMessage from './components/global/GlobalMessage';
import { LogoutModal } from './components/global/LogoutModal';
import { useAppContext } from './contexts/AppContext';
import { AppRouteContextProvider } from './contexts/AppRouteContext';
import HomePage from './pages/HomePage';
import Home from './pages/home/Home';
import ScenarioOverview from './pages/scenario-overview/ScenarioOverview';
import SharedLayout from './pages/SharedLayout';
import { CategoryContextProvider } from './contexts/ScenarioModelCategoryContext';
import { ScenarioContextProvider } from './contexts/ScenarioModelScenarioContext';

const App: React.FC = () => {
  const { toastRef, confirmationModalRef } = useAppContext();

  const unAuthenticatedRoutes = createBrowserRouter(
    createRoutesFromElements(
      <Route>
        <Route index element={<HomePage />} />
        <Route path='*' element={<Navigate to='/' />} />
      </Route>
    )
  );

  const authenticatedRoutes = createBrowserRouter(
    createRoutesFromElements(
      <Route>
        <Route
          path='/'
          element={
            <AppRouteContextProvider>
              <SharedLayout />
            </AppRouteContextProvider>
          }
        >
          <Route
            path="/"
            index element={
              <CategoryContextProvider>
                <ScenarioContextProvider>
                  <Home />
                </ScenarioContextProvider>
              </CategoryContextProvider>
            } />
          <Route
            path='scenario-overview'
            element={
              <CategoryContextProvider>
                <ScenarioContextProvider>
                  <ScenarioOverview />
                </ScenarioContextProvider>
              </CategoryContextProvider>
            } />
        </Route>
      </Route>
    )
  );

  return (
    <React.Fragment>
      <UnauthenticatedTemplate>
        <RouterProvider router={unAuthenticatedRoutes} />
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        <LogoutModal />
        <RouterProvider router={authenticatedRoutes} />
      </AuthenticatedTemplate>
      <GlobalMessage ref={toastRef} />
      <GlobalConfirmationModal ref={confirmationModalRef} />
    </React.Fragment>
  );
};

export default App;
