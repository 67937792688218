import { useEffect, useState } from 'react';
import './Home.scss'
import { useNavigate } from 'react-router-dom';
import { Messages } from '../../enums/Messages';
import { Dropdown } from 'primereact/dropdown';
import { PrimaryButton } from '../../components/global/PrimaryButton';
import Loader from '../../components/loader/Loader';
import { calculateTheSumBasedOnKey, getErrorMessageBasedOnStatusCodes, validateWithSchema } from '../../utils/utils';
import { Field, Form, Formik, FormikValues } from 'formik';
import { formValidation } from '../../constants/formValidation';
import { CategoryActionType, useCategoryContext } from '../../contexts/ScenarioModelCategoryContext';
import { ButtonLabels } from '../../enums/ButtonLabels';
import DefinitionTable from '../../components/definition-table/DefinitionTable';
import { ScenarioHeading } from '../../enums/ScenarioHeading';
import { useGetFinancialScenarioDetails } from '../../hooks/useGetFinancialScenarioDetails';
import { ScenarioActionType, useScenarioContext } from '../../contexts/ScenarioModelScenarioContext';
import { useGetEligibleCategories } from '../../hooks/useGetAllCategoriesList';
import { useGetUserContactDetails } from '../../hooks/useGetUserContactDetails';
import { useMsal } from '@azure/msal-react';
import { useAppContext } from '../../contexts/AppContext';
import { IGlobalMessage } from '../../components/global/GlobalMessage';

export interface ProductFamily {
  productFamilyId: string,
  productFamilyName: string,
  productFamilyToCategoryRatio: number,
  isLocked?: boolean,
}
export interface Category {
  category: string,
  productFamilyDetails: ProductFamily[]
}

interface InitialValue {
  selectedUserPractice: string,
  selectedCategory: string,
}

export interface Scenario {
  heading: string,
  value: ProductFamily[]
}

interface UserContactDetails {
  corporateAccountNumber: string,
  practiceAccountNumber: string
}

interface ProductsAndDistribution {
  ProductFamilyId: string,
  FutureDistribution: number
}

export interface FinancialScenarioRequest {
  AccountNumber: string | undefined,
  ProductsandDistribution: ProductsAndDistribution[]
}

export interface FinancialScenarioResponse {
  scenarioType: string,
  accountNumber: number,
  productFamilyName: string,
  categoryUnits: number,
  estimatedMedicareReimbursementPerEU: number,
  estimatedMedicareReimbursement: number,
  totalEstimatedNCR: number,
  totalEstimatedNCRPerEU: number,
  totalRebate: number
}

const Home = () => {
  const [categories, setCategories] = useState<Category[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<Category>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState<boolean>(true);
  const [scenarios, setScenarios] = useState<Scenario[]>([]);
  const [accountNumber, setAccountNumber] = useState<string>();
  const navigate = useNavigate();
  const { state, dispatch } = useCategoryContext();
  const { accounts } = useMsal();
  const { dispatch: scenariosContextProviderDispatch } = useScenarioContext();
  const { selectedCategory: storedCategory } = state;
  const initialValues = {
    selectedCategory: storedCategory?.category ?? '',
  };
  const { showGlobalMessage } = useAppContext();

  useEffect(() => {
    if (state?.selectedCategory)
      setSelectedCategory(state?.selectedCategory);
    if (state?.scenarios?.length)
      setScenarios(state?.scenarios);
    if (state?.categories?.length)
      setCategories(state?.categories);
  }, [state]);

  useEffect(() => {
    if (accounts.length > 0) {
      const user: any = accounts[0];
      setIsLoading(true);
      getUserContactDetails(user?.idTokenClaims?.email);
    }
  }, [accounts]);

  useEffect(() => {
    let total = calculateTheSumBasedOnKey(scenarios[1]?.value, "productFamilyToCategoryRatio");
    total = total ?? 0;
    setIsSubmitButtonDisabled(total !== 100);
  }, [scenarios[1]]);

  const showMessage = (severity: IGlobalMessage['messageSeverity'], messageDetail: string) => {
    showGlobalMessage({
      messageSummary: '',
      messageDetail: messageDetail,
      messageSeverity: severity,
    });
  };

  const onApiError = (code: number) => {
    setIsLoading(false);
    showMessage('error', getErrorMessageBasedOnStatusCodes(code));
  };

  const onEligibleCategoriesSuccess = (data: Category[]) => {
    setIsLoading(false);
    setCategories(data);
  }

  const onGetFinancialScenarioDetailsSuccess = (data: FinancialScenarioResponse[]) => {
    setIsLoading(false);
    const selectedValues = {
      selectedCategory: selectedCategory,
      scenarios: scenarios,
      categories: categories
    }
    dispatch({ type: CategoryActionType.DEFINE_SCENARIO, payload: selectedValues });
    const futureScenarioDetails = data.reduce((acc: Map<string, FinancialScenarioResponse[]>, futureDetails: FinancialScenarioResponse) => {
      const { scenarioType } = futureDetails;
      if (!acc.has(scenarioType)) {
        acc.set(scenarioType, []);
      }
      acc?.get(scenarioType)?.push(futureDetails);
      return acc;
    }, new Map<string, FinancialScenarioResponse[]>());

    const newFutureScenarioDetails = Array.from(futureScenarioDetails, ([heading, value]) => ({
      heading,
      value
    }));
    scenariosContextProviderDispatch({
      type: ScenarioActionType.DEFINE_SCENARIOS, payload: {
        scenarios: newFutureScenarioDetails
      }
    });
    navigate('/scenario-overview');
  }

  const onGetFinancialScenarioDetailsError = (code: number) => {
    setIsLoading(false);
    onApiError(code);
  }

  const { getFinancialScenarioDetails } = useGetFinancialScenarioDetails({
    onError: onGetFinancialScenarioDetailsError,
    onSuccess: onGetFinancialScenarioDetailsSuccess
  })

  const onEligibleCategoriesError = (code: number) => {
    onApiError(code);
  }

  const onGetUserContactDetailsError = (code: number) => {
    onApiError(code);
  }

  const onGetUserContactDetails = (response: UserContactDetails) => {
    setIsLoading(false);
    if (response?.corporateAccountNumber || response?.practiceAccountNumber) {
      setAccountNumber(response?.corporateAccountNumber ? response?.corporateAccountNumber : response?.practiceAccountNumber);
      getEligibleCategories(response?.corporateAccountNumber ? response?.corporateAccountNumber : response?.practiceAccountNumber);
    }
  }

  const { getUserContactDetails } = useGetUserContactDetails({
    onSuccess: onGetUserContactDetails,
    onError: onGetUserContactDetailsError
  });

  const { getEligibleCategories } = useGetEligibleCategories({
    onSuccess: onEligibleCategoriesSuccess,
    onError: onEligibleCategoriesError
  })

  const handleSelectedCategory = (value: string, formikValue: InitialValue) => {
    if (value) {
      const index = categories.findIndex((category: Category) => category?.category === value);
      const newCategory = categories[index];
      setSelectedCategory(newCategory);
      formikValue.selectedCategory = newCategory.category;
      const currentScenario = newCategory?.productFamilyDetails?.map((productFamily: ProductFamily) => ({
        ...productFamily,
        productFamilyToCategoryRatio: productFamily?.productFamilyToCategoryRatio * 100,
        isLocked: true
      }));
      const newScenario = newCategory?.productFamilyDetails?.map((productFamily: ProductFamily) => ({
        ...productFamily,
        productFamilyToCategoryRatio: productFamily?.productFamilyToCategoryRatio * 100,
        isLocked: false
      }));
      setScenarios([
        {
          heading: ScenarioHeading.CURRENT,
          value: currentScenario,
        },
        {
          heading: ScenarioHeading.SCENARIO + ' 1',
          value: newScenario,
        }
      ]);
    } else {
      setSelectedCategory(undefined);
      setScenarios([]);
      formikValue.selectedCategory = '';
    }
  }

  const handleGenerateScenario = () => {
    setIsLoading(true);
    const newScenario = scenarios?.map((scenario: Scenario) => {
      return scenario?.value?.map((productFamily: ProductFamily) => {
        return {
          ProductFamilyId: productFamily?.productFamilyId,
          FutureDistribution: productFamily?.productFamilyToCategoryRatio ? productFamily?.productFamilyToCategoryRatio / 100 : 0
        };
      })
    });
    getFinancialScenarioDetails({
      AccountNumber: accountNumber,
      ProductsandDistribution: newScenario[1]
    })
  }

  return (
    <div className='main-content'>
      <Formik
        initialValues={initialValues}
        onSubmit={handleGenerateScenario}
        validate={(values) => validateWithSchema(formValidation, values)}
        validateOnChange={true}
      >
        {
          ({ values, errors }: FormikValues) => {
            return (
              <Form>
                <div className='home px-3 pt-3  w-9'>
                  <div className='category-card border-round-md px-3 py-2 flex flex-column gap-3'>
                    <div className='flex flex-column gap-2 pt-1 category w-8'>
                      <label>{"Select Category"}</label>
                      <Field name='selectedCategory'>
                        {
                          () => {
                            return (
                              <Dropdown
                                data-testid='categoryDropDown'
                                value={selectedCategory}
                                onChange={(e) => handleSelectedCategory(e?.value?.category, values)}
                                options={categories}
                                optionLabel='category'
                                placeholder='Select a Category'
                                className='p-inputtext-sm'
                                dataKey='category'
                                filter
                                showFilterClear
                                emptyFilterMessage='No categories found'
                                resetFilterOnHide
                              />
                            )
                          }
                        }
                      </Field>
                      {!values?.selectedCategory && <div className='text-xs text-red-700'>{errors?.selectedCategory}</div>}
                    </div>
                    <div className="table-container">
                      {
                        selectedCategory?.category && scenarios?.map((item: Scenario, number: number) => {
                          return (
                            <div className='table-card' key={`${item.heading} ${number}`}>
                              <div data-testid='scenario-definition-table' />
                              <DefinitionTable
                                index={number}
                                scenarios={scenarios}
                                setScenario={setScenarios}
                              />
                            </div>
                          )
                        })
                      }
                    </div>
                  </div>
                  <div className='flex align-items-center justify-content-end gap-3 mb-2'>
                    {
                      isSubmitButtonDisabled && scenarios.length > 0 && <span data-testid="volume-distribution-exceed-error" className='text-sm text-red-600'>{Messages.VOLUME_DISTRIBUTION_EXCEEDS + scenarios[1]?.heading}</span>
                    }
                    <PrimaryButton type='submit' dataTestId="generate-scenario" label={ButtonLabels.GENERATE_SCENARIO} icon="pi pi-angle-right" iconPos="right" className="text-xs px-3 submit-button" disabled={isSubmitButtonDisabled} />
                  </div>
                </div>
              </Form>
            )
          }}
      </Formik>
      {
        isLoading && <Loader />
      }
    </div>
  )
}

export default Home;
