export const TableHeading = [
    "EU Volume by Product",
    "Reimbursement per EU",
    "Net Cost Recovery per EU",
    "Quarterly Reimbursements",
    "Quarterly Net Cost Recovery",
    "Rebate Estimate"
];

export const VisualHeading = [
    "EU Volume by Product",
    "Rebate Estimate",
    "Reimbursement per EU",
    "Quarterly Reimbursements",
    "Net Cost Recovery per EU",
    "Quarterly Net Cost Recovery",
]